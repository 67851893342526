/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    '7K9PZh47gaNvBrkfSvU3U3.png': { uri: '/images/7K9PZh47gaNvBrkfSvU3U3.png' },
'7hL75USXQUsfkFHQKVaGhm.png': { uri: '/images/7hL75USXQUsfkFHQKVaGhm.png' },
'6eaq8HkTUyJBtw3LJBeYzY.png': { uri: '/images/6eaq8HkTUyJBtw3LJBeYzY.png' },
'br8jg3DZrPvYcfcnU4qAyX.png': { uri: '/images/br8jg3DZrPvYcfcnU4qAyX.png' },
'7AhxSbeMNWqxX6nmk4AxD8.png': { uri: '/images/7AhxSbeMNWqxX6nmk4AxD8.png' },
'xeWXuah8B43xRoFLeR2G67.png': { uri: '/images/xeWXuah8B43xRoFLeR2G67.png' },
'5RiXqUcFbejTx9rai62VwZ.png': { uri: '/images/5RiXqUcFbejTx9rai62VwZ.png' },
'nuLPKNcWX771WnCqX6MUDB.png': { uri: '/images/nuLPKNcWX771WnCqX6MUDB.png' },
'7bmYiqpCAycFt6Bb7Q6yD1.png': { uri: '/images/7bmYiqpCAycFt6Bb7Q6yD1.png' },
'fv7xuzSqRZMiQvZJ3efdQJ.png': { uri: '/images/fv7xuzSqRZMiQvZJ3efdQJ.png' },
'wdcHt981JuyHz2ZqieV95c.png': { uri: '/images/wdcHt981JuyHz2ZqieV95c.png' },
'pgwUhLyjTM2ciTxZVANFFw.png': { uri: '/images/pgwUhLyjTM2ciTxZVANFFw.png' },
'ohX4oE7LAd8jmtCUNV3vCt.png': { uri: '/images/ohX4oE7LAd8jmtCUNV3vCt.png' },
'2DwrwwZEMqLEZqUFPFikh8.png': { uri: '/images/2DwrwwZEMqLEZqUFPFikh8.png' },
'rQooMEAYqE5HMwBQjMZxeM.png': { uri: '/images/rQooMEAYqE5HMwBQjMZxeM.png' },
'q8Lu7MZqL5xuGAssz6btBV.png': { uri: '/images/q8Lu7MZqL5xuGAssz6btBV.png' },
'6ztCiMaxBvNZ3cU8GHjZEP.png': { uri: '/images/6ztCiMaxBvNZ3cU8GHjZEP.png' },
'hGjuihhPkbgFeSz6QBvyfZ.png': { uri: '/images/hGjuihhPkbgFeSz6QBvyfZ.png' },
'rCVcvMobGj8S5LgBUFZkLA.png': { uri: '/images/rCVcvMobGj8S5LgBUFZkLA.png' },
'eYGU7vyHejerzjGqCM4F5Q.png': { uri: '/images/eYGU7vyHejerzjGqCM4F5Q.png' },
'dngMPgXbtEcKsw3xaV3bBK.png': { uri: '/images/dngMPgXbtEcKsw3xaV3bBK.png' },
'nx5GPqAv5o768a7z8UWp4z.png': { uri: '/images/nx5GPqAv5o768a7z8UWp4z.png' },
'6GK943btANX1eFyabuRjP8.png': { uri: '/images/6GK943btANX1eFyabuRjP8.png' },
'57Ld1vN9TbtXvniUePDj6Q.png': { uri: '/images/57Ld1vN9TbtXvniUePDj6Q.png' },
'qkUYgKd4vGEEQauhDE6Wb1.png': { uri: '/images/qkUYgKd4vGEEQauhDE6Wb1.png' },
'6ME4k72GqtHcMgb5KWcbSa.png': { uri: '/images/6ME4k72GqtHcMgb5KWcbSa.png' },
'ogkfJaCmXgZpv6duzcrYnP.png': { uri: '/images/ogkfJaCmXgZpv6duzcrYnP.png' },
'u2DbUWMksyDMmADY8u6z1j.png': { uri: '/images/u2DbUWMksyDMmADY8u6z1j.png' },
'qp7dvTy1FGk77nQgx5rm4H.png': { uri: '/images/qp7dvTy1FGk77nQgx5rm4H.png' },
'3jUjwHXmw5TtnDpFNMmGEo.png': { uri: '/images/3jUjwHXmw5TtnDpFNMmGEo.png' },
'e7n8u5eDBDSP7i56b4XdL3.png': { uri: '/images/e7n8u5eDBDSP7i56b4XdL3.png' },
'jdq8aWypdFAtJS6GpBaNVA.png': { uri: '/images/jdq8aWypdFAtJS6GpBaNVA.png' },
'5Ds1LPGRUPMi5sTaXNto4j.png': { uri: '/images/5Ds1LPGRUPMi5sTaXNto4j.png' },
'in9wMFvRBVAPUqfXBqbpoa.png': { uri: '/images/in9wMFvRBVAPUqfXBqbpoa.png' },
'jvwj4jRJTmTyHJprDqeqsx.png': { uri: '/images/jvwj4jRJTmTyHJprDqeqsx.png' },
'p9N4Dq9vycs2vV3cPpJNpQ.png': { uri: '/images/p9N4Dq9vycs2vV3cPpJNpQ.png' },
'jVMwDpafkcYbxXz9R6yGBg.png': { uri: '/images/jVMwDpafkcYbxXz9R6yGBg.png' },
'68RJVe8M2if5dMz1B3zeTU.png': { uri: '/images/68RJVe8M2if5dMz1B3zeTU.png' },
'iQF2fYprR3m4ePBkCedchL.png': { uri: '/images/iQF2fYprR3m4ePBkCedchL.png' },
'hAikgxiZ8Et5eLGEbMFEKo.png': { uri: '/images/hAikgxiZ8Et5eLGEbMFEKo.png' },
'uEFve5sypUnCuQ5JJLRyhf.png': { uri: '/images/uEFve5sypUnCuQ5JJLRyhf.png' },
'vSA7kahqhXtSCQNZgJ4JvF.png': { uri: '/images/vSA7kahqhXtSCQNZgJ4JvF.png' },
'tFDDs4tbFZ6ED5fncQXWr2.png': { uri: '/images/tFDDs4tbFZ6ED5fncQXWr2.png' },
'cmMETmgsc6X6rHYA47QsWz.png': { uri: '/images/cmMETmgsc6X6rHYA47QsWz.png' },
'1KeZtPMExvsddB33Z6BZAL.png': { uri: '/images/1KeZtPMExvsddB33Z6BZAL.png' },
'1xkk7BfXPiy6iYhW7ssWS3.png': { uri: '/images/1xkk7BfXPiy6iYhW7ssWS3.png' },
'aqbW8m8r1SHfSD8Jm2FFjb.png': { uri: '/images/aqbW8m8r1SHfSD8Jm2FFjb.png' },
'boeAiX94qdiXo65m8vqv3m.png': { uri: '/images/boeAiX94qdiXo65m8vqv3m.png' },
'atijFEsKMj2qtGNPkmPB4q.png': { uri: '/images/atijFEsKMj2qtGNPkmPB4q.png' },
'xnA6N2MiaYQyMBzvzznK5J.png': { uri: '/images/xnA6N2MiaYQyMBzvzznK5J.png' },
'82zjYK7iJxQ7wcJ2LAdfBK.png': { uri: '/images/82zjYK7iJxQ7wcJ2LAdfBK.png' },
'9Q2N722Lm28UG15Q5Gkogt.png': { uri: '/images/9Q2N722Lm28UG15Q5Gkogt.png' },
'r64k5Q23JWh4MoeA1kpCKw.png': { uri: '/images/r64k5Q23JWh4MoeA1kpCKw.png' },
'7HqZ7ySxk6ix3jvfwvdwRW.png': { uri: '/images/7HqZ7ySxk6ix3jvfwvdwRW.png' },
'5UncKj7ndy1GqLjXscphtc.png': { uri: '/images/5UncKj7ndy1GqLjXscphtc.png' },
'5LSYVtgu1hRQyppYVe7LCg.png': { uri: '/images/5LSYVtgu1hRQyppYVe7LCg.png' },
'iT9XbncwdhPAPhWrVauX8J.png': { uri: '/images/iT9XbncwdhPAPhWrVauX8J.png' },
'fuFQ1r8mAABB3nVfdBAEKb.png': { uri: '/images/fuFQ1r8mAABB3nVfdBAEKb.png' },
'jhpXozjqM4ZbN2QX8EEAwa.png': { uri: '/images/jhpXozjqM4ZbN2QX8EEAwa.png' },
'6wwFsNA9UwonVHEXrygEG8.png': { uri: '/images/6wwFsNA9UwonVHEXrygEG8.png' },
'8zdvgWSj25yS7o1rHfqmTb.png': { uri: '/images/8zdvgWSj25yS7o1rHfqmTb.png' },
'sZ24AiY8YzsTRUQAuSKTu6.png': { uri: '/images/sZ24AiY8YzsTRUQAuSKTu6.png' },
'tw7i6j5YbfmKEXpksSnhLy.png': { uri: '/images/tw7i6j5YbfmKEXpksSnhLy.png' },
'jQGMmKDqcCmmjqESG3xYGG.png': { uri: '/images/jQGMmKDqcCmmjqESG3xYGG.png' },
'7CxMmQtaEhfP9yo6P1xD6G.png': { uri: '/images/7CxMmQtaEhfP9yo6P1xD6G.png' },
'owfzquksiLeBvCPhWQQH1g.png': { uri: '/images/owfzquksiLeBvCPhWQQH1g.png' },
'vgQRohZZEYWhxR1gs3qPeV.png': { uri: '/images/vgQRohZZEYWhxR1gs3qPeV.png' },
'dmLPDGWC9XQy2hFVevKfi7.png': { uri: '/images/dmLPDGWC9XQy2hFVevKfi7.png' },
'2QpvWnCkN7WwdPxUvFJdVk.png': { uri: '/images/2QpvWnCkN7WwdPxUvFJdVk.png' },
'9GwYwfjHMudL4KgHBSkRSz.png': { uri: '/images/9GwYwfjHMudL4KgHBSkRSz.png' },
'uqZNP2f2jMrz4etFUTpMzp.png': { uri: '/images/uqZNP2f2jMrz4etFUTpMzp.png' },
'ibVUqtzDDahL9HzgBGBWYf.png': { uri: '/images/ibVUqtzDDahL9HzgBGBWYf.png' },
'2TXsNetUH6ivJDoMiES52z.png': { uri: '/images/2TXsNetUH6ivJDoMiES52z.png' },
'j1HfWqUgkebFXmDBDBv5Fo.jpg': { uri: '/images/j1HfWqUgkebFXmDBDBv5Fo.jpg' },
'9MsiKHQgzD6bMtuyZRWBQa.png': { uri: '/images/9MsiKHQgzD6bMtuyZRWBQa.png' },
'bv8ZmLzjS11GBnUqpDYwjj.png': { uri: '/images/bv8ZmLzjS11GBnUqpDYwjj.png' },
'mDCKd8Jt8nLGmUeto5E8Ld.jpg': { uri: '/images/mDCKd8Jt8nLGmUeto5E8Ld.jpg' },
'kDcYJjek1YLHNUXNZrQYta.jpg': { uri: '/images/kDcYJjek1YLHNUXNZrQYta.jpg' },
'26qXLWWvciCWhF4s6deEF2.jpg': { uri: '/images/26qXLWWvciCWhF4s6deEF2.jpg' },
'uSeZLn2K3FZXCYKHF2qigZ.png': { uri: '/images/uSeZLn2K3FZXCYKHF2qigZ.png' },
'fXNQwYF1B68UGTSt8PfQYB.jpg': { uri: '/images/fXNQwYF1B68UGTSt8PfQYB.jpg' },
'vWw9ZV6hDrn648Fp7cFH7x.jpg': { uri: '/images/vWw9ZV6hDrn648Fp7cFH7x.jpg' },
'8nbmZrgDCQSFVzcYpwGJ8c.jpg': { uri: '/images/8nbmZrgDCQSFVzcYpwGJ8c.jpg' },
'jFkqzZvokUeKX1Wpxxmut3.png': { uri: '/images/jFkqzZvokUeKX1Wpxxmut3.png' },
'emEvT2bnwdAUM8Xj1X4xgc.png': { uri: '/images/emEvT2bnwdAUM8Xj1X4xgc.png' },
'vNTayPG6kS15UfAgZwZXco.jpg': { uri: '/images/vNTayPG6kS15UfAgZwZXco.jpg' },
'6zuh3WBD9KsMKSgVFBHrch.png': { uri: '/images/6zuh3WBD9KsMKSgVFBHrch.png' }
}

export default imageStaticSourcesByFileName
