import flatBuildOrdersControls from '@apphiveio/controlsmanager/functions/flatters/flatBuildOrdersControls'
// THIS VALUES ARE USED DURING CLIENT APP COMPILATION, PLEASE DO NOT MOVE
const initialAppId = 'app_on4PfztYvG2TtJ8XqpziS7'
const initialProjectId = 'proj_dJYvbJcWCD'
const initialBuildOrdersOnApp = require('./buildOrders.loaded.json') // eslint-disable-line
const initialRunningMode = 'premium'
const initialCustomFontName = undefined
// #################

export const initialAppState = {
    appId: initialAppId,
    projectId: initialProjectId,
    buildOrders: initialBuildOrdersOnApp,
    runningMode: initialRunningMode,
    customFontName: initialCustomFontName,
} as const
